.verzweigen__background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 5;
}

.verzweigen__title {
    color: white;
    right: 20px;
    text-align: right;
    font-family: 'title';
    font-size: 80px;
    // -webkit-text-stroke: 1px white;
    letter-spacing: 5px;
    color: white;
    bottom: 120px;
    width: 100%;
    text-transform: uppercase;
    z-index: 2;
}

.verzweigen__text1 {
    left: 0;
    font-size: 7vw;
    width: 400vw;
    height: 30vw;
    font-family: 'reg';
    margin-left: 30px;
    transform-origin:center;
    line-height: 100%;
    z-index: 3;
    color: transparent;
    -webkit-text-stroke: 1px white;
}

.verzweigen__text1 p {
    margin-top: 0;
    margin-bottom: 0;
}

.verzweigen__text1__b {
    margin-left: 100vw;
}
.verzweigen__text1__c {
    margin-left: 200vw;
}
.verzweigen__text1__d {
    margin-left: 300vw;
}

.verzweigen__video1, .verzweigen__video2 {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 100px;
    box-sizing: border-box;
}

.verzweigen__video1 video, .verzweigen__video2 video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.verzweigen__lines {
    top: 0;
    left: 0;
    z-index: 100000;
    pointer-events: all;
}

.verzweigen__lines {
    // stroke-dasharray: 1000;
    // stroke-dashoffset: 1000;
    // animation: dash 5s linear infinite;
    // pointer-events: none;
}

.verzweigen__text2 {
    left: 0;
    top: 0;
    transform-origin: center;
    text-transform: uppercase;
    font-size: 100px;
    line-height: 80%;
    width: 100vw;
    height: 100vh;
    color: transparent;
    z-index: 3000000000;
    display: flex;
    // align-items: flex-start;
    // justify-content: flex-start;
    transform-origin: center;
    position: fixed;
    // z-index: 4;
    mix-blend-mode: difference;
    font-family: sans-serif;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.verzweigen__text2__container {
    width: 100vh;
    height: 100vw;
    transform-origin: center;
    transform: rotate(-90deg);
    margin-left: 30px;
    margin-bottom: 30px;
}

// .verzweigen__text2 p:hover{
//     color: black;
//     cursor: pointer;
//     width: 100%;
// }

.verzweigen__text2 p {
    overflow: hidden;
    word-break: break-all;
    // height: 70px;
    height: fit-content;
    margin: 0;
    -webkit-text-stroke: 1px white;
    color: white;
}
  

@keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

//   .userphoto--3 {
//       width: 100%;
//       height: 100%;
//       top: 0;
//       left: 0;
//       box-sizing: border-box;
//       z-index: 2;
//   }

//   .userphoto--3 img {
//       top: 0;
//       left: 0;
//   }

//   .userphoto--3 .p5Seed {
//       width: calc(80vh - 140px);
//       height: calc(80vh - 140px);
//       margin-top: 90px;
//       margin-left: calc(50vw - 40vh + 70px);
//   }

  .verzweigen__gif1 {
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      padding: 20vw;
    }

  .gif1__block {
      width: 300px;
      height: 300px;
      left: 30vw;
      top: 30vw;
      position: fixed;
      object-fit: cover;
  }

  .gif1__block--2{
    width: 300px;
    height: 300px;
    object-fit: cover;
    filter: invert(1);
    margin-left: 100px;
    margin-top: 10vh;
}

// .gif1__block--100,.gif2__block--100 {
//     margin-top: 0;
// }
.gif1__block--101, .gif2__block--101 {
margin-top: 15vh;
}
.gif1__block--102, .gif2__block--102 {
    margin-top: 30vh;
}
.gif1__block--103, .gif2__block--103 {
    margin-top: 45vh;
}
.gif1__block--104, .gif2__block--104 {
    margin-top: 60vh;
}
.gif1__block--105, .gif2__block--105 {
    margin-top: 75vh;
}
.gif1__block--106, .gif2__block--106 {
    margin-top: 90vh;
}

.verzweigen__text3 {
    width: 100%;
    height: 100%;
    top: 100px;
    left: 100px;
    font-size: 90px;
    font-family: 'title';
    color: white;
    line-height: 100%;
    z-index: 1000;
}

.verzweigen__text3__w {
    -webkit-text-stroke: white 1px;
    color: transparent;
    margin-right: 30px;
    animation: slowblink 4s infinite ease-in-out;
}

#verzweigen__all, #verzweigen__algo {
    position: absolute;
    right: 200px;
    bottom: 170px;
    color: orangered;
    -webkit-text-stroke: white 1px;
    display: block;
    opacity: 0.2;
    animation: blink 1s infinite;
}

.verzweigen__text3__w:hover {
    color: orangered;
    cursor: pointer;
    animation-duration: 0s;
}
.verzweigen__text3__welt {
    animation-delay: 2s;
}

.verzweigen__text3__welt:hover ~ #verzweigen__all {
    opacity: 1;
    animation: blink 0.1s infinite;
}

.verzweigen__text3__wald:hover ~ #verzweigen__algo {
    opacity: 1;
    animation: blink 0.1s infinite;
}

.verzweigen__text3__welt:hover ~ .gif1__block  {
    border: solid 1px orangered;
}

.verzweigen__text3__wald:hover ~ .gif2__block {
    border: solid 1px orangered;
}

@keyframes blink {
    0%{ opacity:1 }
    49%{ opacity:1 }
    50%{ opacity: 0; }
    99%{ opacity: 0; }
    100%{ opacity:1 }
}

@keyframes slowblink {
    0%{ color: rgba(255,69,0,0); }
    50%{ color: rgba(255,69,0,0); }
    60%{ color: rgba(255,69,0,1); }
    70%{ color: rgba(255,69,0,0); }
    80%{ color: rgba(255,69,0,1); }
    90%{ color: rgba(255,69,0,0); }
    100%{ color: rgba(255,69,0,0); }
}

.verzweigen__lines {
    display: none;
}

.verzweigen__lines, .verzweigen__lines__container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.verzweigen__lines__container {
    display: none;
    pointer-events: none;
}

.verzweigen__lines div div {
    position: fixed;
    transform-origin: right;
}

.verzweigen__lines__1__a {
    top: 24vh;
    right: 40.7vw;
    width: 49vw;
    height: 1px;
    transform: rotate(3.5deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__1__b {
    top: 24vh;
    right: 40.7vw;
    width: 72vh;
    height: 1px;
    transform: rotate(-40deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__1__c {
    top: 24vh;
    right: 40.7vw;
    width: 32vh;
    height: 1px;
    transform: rotate(-80deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__2__a {
    top: 55.5vh;
    right: 44.2vw;
    width: 51vw;
    height: 1px;
    transform: rotate(26deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__2__b {
    top: 55.5vh;
    right: 44.2vw;
    width: 52vh;
    height: 1px;
    transform: rotate(-16deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__2__c {
    // OK
    top: 55.5vh;
    right: 44.2vw;
    width: 32vh;
    height: 1px;
    transform: rotate(100deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__3__a {
    top: 70.5vh;
    right: 75.2vw;
    width: 57vh;
    height: 1px;
    transform: rotate(63deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__3__b {
    top: 70.5vh;
    right: 75.2vw;
    width: 42vw;
    height: 1px;
    transform: rotate(143deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__3__c {
    top: 70.5vh;
    right: 75.2vw;
    width: 58vh;
    height: 1px;
    transform: rotate(165deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__4__a {
    top: 20vh;
    right: 90.2vw;
    width: 57vh;
    height: 1px;
    transform: rotate(-118deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__4__b {
    top: 20vh;
    right: 90.2vw;
    width: 50vw;
    height: 1px;
    transform: rotate(203.5deg);
    border-bottom: 1px solid white;
}

.verzweigen__lines__4__c {
    top: 20vh;
    right: 90.2vw;
    width: 49vw;
    height: 1px;
    transform: rotate(182deg);
    border-bottom: 1px solid white;
}


.verzweigen__hovercircles {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    opacity: 0.3;
    z-index: 100000;
}

.verzweigen__hovercircles__1 {
    height: 23vh;
    width: 23vh;
    position: fixed;
    right: 37.5vw;
    top: 44vh;
    border-radius: 50%;
    cursor: pointer;
}

.verzweigen__hovercircles__2 {
    height: 33vh;
    width: 33vh;
    position: fixed;
    right: 33.5vw;
    top: 9vh;
    border-radius: 50%;
    cursor: pointer;
}

.verzweigen__hovercircles__3 {
    height: 42vh;
    width: 42vh;
    position: fixed;
    right: 61vw;
    top: 49vh;
    border-radius: 50%;
    cursor: pointer;
}

.verzweigen__hovercircles__4 {
    height: 20vh;
    width: 20vh;
    position: fixed;
    right: 83vw;
    top: 11vh;
    border-radius: 50%;
    cursor: pointer;
}

.verzweigen__hovercircles__1:hover ~ .verzweigen__lines__2 {
    display: block;
}

.verzweigen__hovercircles__2:hover ~ .verzweigen__lines__1 {
    display: block;
}

.verzweigen__hovercircles__3:hover ~ .verzweigen__lines__3 {
    display: block;
}

.verzweigen__hovercircles__4:hover ~ .verzweigen__lines__4 {
    display: block;
}

.verzweigen__floater {
    width: 200px;
}

.verzweigen__floater--2 {
    width: 400px;
    margin-top: -200px;
}

.verzweigen__floater--3 {
    width: 500px;
}

.verzweigen__floater--1 {
    width: 150px;
}

.verzweigen__floater--4 {
    z-index: 1000000;
}

.verzweigen__floater--5 {
    width: 250px;
}

.verzweigen__branching {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    filter:invert(1)
}

.verzweigen__branching img {
    object-fit: 150vh 150vw;
}