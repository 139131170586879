#intro {
    height: 100vh;
}

.intro__background-2 {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.intro__background-2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.section--intro {
    display: flex;
    align-items: center;
    justify-content: center;
}

.intro__introVideo {
    z-index: 3;
    width: 25vh;
    border-radius: 50%;
    overflow: hidden;
    transition-duration: 1s;
    transition-property: opacity;
    left: calc(50vw - 12.5vh);
}

.intro__introVideo div, .intro__introVideo div video {
  height: 100%;
  width: 100%;
  padding: 0;
}

.intro__presentationVideo {
    z-index: 2;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    padding: 70px 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
}

.intro__presentationVideo video, 
.intro__introVideo video {
    object-fit: contain;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    object-position: top;
    z-index: 2;
}

h2 {
    font-family: 'pixel';
    font-size: 30px;
    color: white;
    margin: 20px;
    line-height: 30px;
    margin: 0;
}

h1 {
    font-family: 'title';
    font-size: 6vw;
    color: transparent;
    -webkit-text-stroke: 0.1px white;
    color: rgba(255,255,255,0.3);
    -webkit-font-smoothing: antialiased;
}

.intro__text {
    top: 0;
    left: 0;
    z-index: 30;
    text-align: center;
    line-height: 15vh;
    padding: 15vh;
    width: 100vw;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.intro__click {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,1);
    transition-duration: 0.5s;
    transition-property: 'background-color';
}

.intro__click__square {
    width: calc(25vh - 4px);
    height: calc(25vh - 4px);
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    font-family: 'reg';
    z-index: 5;
    margin-left: -1px;
    margin-top: -1px;
    pointer-events: none;
    color: black;
    border: solid 1px white;
}

.intro__click__square:hover {
    background-color: transparent;
}

.intro__floater--1 {
    height: 400px;
    width: 300px;
}

.intro__floater--2 {
    height: 200px;
    width: 600px;
}

.intro__floater img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
