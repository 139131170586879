.video-wrapper {
  width: 100%;
  height: 100%;
}

.video-container {
  position: relative;
  /* TEMPORARY FIX */
  height: 100%;;
  /* padding-bottom: 56.25%;
  height: 0; */
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .intro__introVideo .video-container {
  padding-bottom: 100% !important;
} */
